<div class="floating-menu">
  <button class="btn-ayuda-flotante main-button" (click)="toggleMenu()">
    <img src="assets/img/hwinfo.svg" alt="Menú">
  </button>

  <div class="menu-items" [ngClass]="{'active': isMenuOpen}">
    <button class="menu-item" (click)="irAReportarError()">
      <i class="fas fa-question-circle"></i>
      <span class="tooltip">Reportar Error</span>
    </button>
    
    <div class="menu-item-container">
      <button class="menu-item" (click)="toggleBarcodeOptions($event)">
        <i class="fas fa-barcode"></i>
        <span class="tooltip">Generar Código</span>
      </button>
      
      <div class="barcode-options" [ngClass]="{'active': showBarcodeOptions}">
        <div class="options-content">
          <div class="form-group">
            <label>Tipo</label>
            <select [(ngModel)]="barcodeType" class="form-control">
              <option value="FAST">FAST</option>
              <option value="DEPO">DEPO</option>
            </select>
          </div>
          <div class="form-group">
            <label>Cantidad</label>
            <input
              type="number"
              [(ngModel)]="barcodeQuantity"
              class="form-control"
              min="1"
              placeholder="Cantidad"
            />
          </div>
          <button class="btn btn-primary btn-sm w-100" (click)="generateBarcodes()">
            Generar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Resultados -->
<div class="modal fade" #barcodeResultModal tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fas fa-barcode mr-2"></i>
          Códigos de Barra Generados
        </h5>
        <button type="button" class="close" (click)="closeResultModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="barcodes-container">
          <div
            *ngFor="let code of generatedBarcodes; let i = index"
            class="barcode-item"
          >
            <svg id="barcode-{{ i }}"></svg>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeResultModal()">
          Cerrar
        </button>
        <button type="button" class="btn btn-primary" (click)="printBarcodes()">
          <i class="fas fa-print mr-2"></i>
          Imprimir
        </button>
      </div>
    </div>
  </div>
</div>
