import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.css']
})
export class FloatingMenuComponent {
  isMenuOpen = false;
  showBarcodeOptions = false;
  barcodeType: string = 'FAST';
  barcodeQuantity: number = 1;
  generatedBarcodes: string[] = [];

  @ViewChild('barcodeResultModal') barcodeResultModal: ElementRef;

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) {}

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (!this.isMenuOpen) {
      this.showBarcodeOptions = false;
    }
  }

  toggleBarcodeOptions(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.showBarcodeOptions = !this.showBarcodeOptions;
  }

  irAReportarError(): void {
    this.router.navigate(['/help/center']);
    this.isMenuOpen = false;
  }

  async generateBarcodes() {
    if (!this.barcodeQuantity || this.barcodeQuantity < 1) {
      this.toastr.error('Por favor ingrese una cantidad válida');
      return;
    }

    this.generatedBarcodes = [];
    
    for (let i = 0; i < this.barcodeQuantity; i++) {
      const now = new Date();
      const utcTimestamp = now.getTime();
      
      const barcode = `${this.barcodeType}_${now.getUTCFullYear()}${String(now.getUTCMonth() + 1).padStart(2, '0')}${String(now.getUTCDate()).padStart(2, '0')}_${String(now.getUTCHours()).padStart(2, '0')}${String(now.getUTCMinutes()).padStart(2, '0')}${String(now.getUTCSeconds()).padStart(2, '0')}_${String(utcTimestamp % 1000).padStart(3, '0')}`;
      
      this.generatedBarcodes.push(barcode);
      await new Promise(r => setTimeout(r, 1));
    }

    // Cerrar opciones y mostrar modal de resultados
    this.showBarcodeOptions = false;
    
    const resultModal = this.barcodeResultModal.nativeElement;
    resultModal.classList.add('show');
    resultModal.style.display = 'block';
    document.body.classList.add('modal-open');

    // Generar códigos de barra
    setTimeout(() => {
      this.generatedBarcodes.forEach((code, index) => {
        JsBarcode(`#barcode-${index}`, code, {
          width: 2,
          height: 100,
          fontSize: 14,
          displayValue: true,
          format: 'CODE128',
          margin: 20,
          background: '#ffffff',
          lineColor: '#000000',
          textAlign: 'center',
          textPosition: 'bottom',
          textMargin: 8,
          font: 'monospace'
        });
      });
    }, 100);
    
    this.toastr.success(`Se generaron ${this.barcodeQuantity} códigos de barra`);
  }

  closeResultModal() {
    const modal = this.barcodeResultModal.nativeElement;
    modal.classList.remove('show');
    modal.style.display = 'none';
    document.body.classList.remove('modal-open');
  }

  printBarcodes() {
    const printContents = document.querySelector('.barcodes-container').innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = `
      <style>
        .barcode-item { 
          margin-bottom: 20px;
          page-break-inside: avoid;
        }
      </style>
      <div class="barcodes-container">
        ${printContents}
      </div>
    `;

    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }
}
