import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private idleTimeout: any;
  private readonly idleTimeInMs: number = 5400000; // 01:30 minutos
  private user: any;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private toastrService: ToastrService
  ) {
    this.startWatching();
  }

  private resetTimer() {
    if (this.router.url === '/traceability') {
      return;
    }

    if (this.router.url === '/complaints') {
      return;
    }

    if (this.router.url === '/tariff') {
      return;
    }

    clearTimeout(this.idleTimeout);
    if (this.router.url.indexOf('truck/travel/planification') === -1) {
      this.idleTimeout = setTimeout(() => this.logout(), this.idleTimeInMs);
    }

    this.user = localStorage.getItem('user');

    if (this.router.url === '/auth/register') {
      return;
    }

    if (this.router.url === '/auth/recover-password') {
      return;
    }

    if (this.router.url.indexOf('/auth/login') === -1 && this.router.url != '/') {
      if (!this.user) {
        this.logout();
      }
    }
  }

  private logout() {
    this.toastrService.clear();
    this.toastrService.show(
      `<span class="now-ui-icons ui-1_bell-53"></span>Sesión cerrada por inactividad.</b>`,
      '',
      {
        disableTimeOut: true,
        closeButton: true,
        enableHtml: true,
        toastClass: 'alert alert-danger alert-with-icon',
        positionClass: 'toast-top-right',
      }
    );
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  private startWatching() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mousemove', () => this.resetTimer());
      window.addEventListener('keydown', () => this.resetTimer());
      window.addEventListener('click', () => this.resetTimer());
      window.addEventListener('scroll', () => this.resetTimer());
      window.addEventListener('touchstart', () => this.resetTimer());
      this.resetTimer();
    });
  }
}
