import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';

@NgModule({
  declarations: [
    FloatingMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    FloatingMenuComponent,
    CommonModule,
    FormsModule
  ]
    })
export class SharedModule { }